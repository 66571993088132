<template>
  <div class="pricingPlan">
    <section class="page page1">
      <div class="title">{{$t('price.title_1')}}</div>
      <div class="subtitle">{{$t('price.subtitle_1')}}</div>

      <div class="plans">
        <template v-for="(plan, i) in plans">
          <div class="plan" :key="i">
            <div v-show="plan.tag" class="planTag">{{$t(`price.plan_${i + 1}_tag`)}}</div>
            <div class="planName">{{$t(`price.plan_${i + 1}_name`)}}</div>
            <div class="planPrice">
              <div class="planDiscountPrice">{{plan.discountPrice}}</div>
              <div class="planOriginalPrice">{{plan.originalPrice}}</div>
            </div>
            <div class="planContent">{{$t(`price.plan_${i + 1}_content`)}}</div>
            <div class="buyBtn" @click="buyHandle(i)">{{$t('price.buy_button')}}</div>
          </div>
        </template>
      </div>

      <div class="planExplains">
        <div class="explainItem">
          <img class="explainIcon" src="@/assets/images/pricing/planIcon1.png" alt="planExplainIcon">
          <div class="explainText">{{$t('price.page_1_tip_1')}}</div>
        </div>
        <div class="explainItem">
          <img class="explainIcon" src="@/assets/images/pricing/planIcon2.png" alt="planExplainIcon">
          <div class="explainText">{{$t('price.page_1_tip_2')}}</div>
        </div>
      </div>
    </section>

    <section class="page page2">
      <div class="title">{{$t('price.title_2')}}</div>
      <div class="introItems">
        <div class="introItem">
          <img class="introIcon" src="@/assets/images/pricing/includeIcon1.png" alt="introduceIcon">
          <div class="introWrap">
            <div class="introTitle">{{$t('price.page_2_title_1')}}</div>
            <div class="introContent">{{$t('price.page_2_content_1')}}</div>
          </div>
        </div>
        <div class="introItem">
          <img class="introIcon" src="@/assets/images/pricing/includeIcon2.png" alt="introduceIcon">
          <div class="introWrap">
            <div class="introTitle">{{$t('price.page_2_title_2')}}</div>
            <div class="introContent">{{$t('price.page_2_content_2')}}</div>
          </div>
        </div>
        <div class="introItem">
          <img class="introIcon" src="@/assets/images/pricing/includeIcon3.png" alt="introduceIcon">
          <div class="introWrap">
            <div class="introTitle">{{$t('price.page_2_title_3')}}</div>
            <div class="introContent">{{$t('price.page_2_content_3')}}</div>
          </div>
        </div>
        <div class="introItem">
          <img class="introIcon" src="@/assets/images/pricing/includeIcon4.png" alt="introduceIcon">
          <div class="introWrap">
            <div class="introTitle">{{$t('price.page_2_title_4')}}</div>
            <div class="introContent">{{$t('price.page_2_content_4')}}</div>
          </div>
        </div>
        <div class="introItem">
          <img class="introIcon" src="@/assets/images/pricing/includeIcon5.png" alt="introduceIcon">
          <div class="introWrap">
            <div class="introTitle">{{$t('price.page_2_title_5')}}</div>
            <div class="introContent">{{$t('price.page_2_content_5')}}</div>
          </div>
        </div>
        <div class="introItem">
          <img class="introIcon" src="@/assets/images/pricing/includeIcon6.png" alt="introduceIcon">
          <div class="introWrap">
            <div class="introTitle">{{$t('price.page_2_title_6')}}</div>
            <div class="introContent">{{$t('price.page_2_content_6')}}</div>
          </div>
        </div>
      </div>
    </section>

    <section class="page page3">
      <div class="title">{{$t('price.title_3')}}</div>
      <div class="faqItems">
        <div class="faqItem" :class="{active: answerIndex === 1}">
          <img class="arrowIcon" src="@/assets/images/pricing/arrow.png" alt="arrow">
          <div class="question" @click="showAnswer(1)">{{$t('price.question_1')}}</div>
          <div class="answer">{{$t('price.answer_1')}}</div>
        </div>
        <div class="faqItem" :class="{active: answerIndex === 2}">
          <img class="arrowIcon" src="@/assets/images/pricing/arrow.png" alt="arrow">
          <div class="question" @click="showAnswer(2)">{{$t('price.question_2')}}</div>
          <div class="answer">{{$t('price.answer_2')}}</div>
        </div>
        <div class="faqItem" :class="{active: answerIndex === 3}">
          <img class="arrowIcon" src="@/assets/images/pricing/arrow.png" alt="arrow">
          <div class="question" @click="showAnswer(3)">{{$t('price.question_3')}}</div>
          <div class="answer" v-html="$t('price.answer_3')"></div>
        </div>
      </div>
      <div class="help">{{$t('price.need_help')}} <a href="mailto:support@fluenday.com">{{$t('price.contact_us')}}</a></div>
    </section>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { getPlans } from '@/api/order'

  export default {
    data() {
      return {
        plans: [
          {
            plan: 'FluenDay Premium - Monthly',
            name: 'monthly',
            tag: '',
            originalPrice: '',
            discountPrice: '',
          },
          {
            plan: 'FluenDay Premium - Yearly',
            name: 'annual',
            tag: '60_off',
            originalPrice: '',
            discountPrice: '',
          },
          {
            plan: 'FluenDay Premium - Six Years',
            name: '6_year',
            tag: 'best_value',
            originalPrice: '',
            discountPrice: '',
          }
        ],
        answerIndex: 0
      }
    },
    inject: ['signIn'],
    computed: {
      ...Vuex.mapGetters(['userLanguage', 'userInfo', 'productsInfo'])
    },
    mounted() {
      this.init()
    },
    methods: {
      async init() {
        let productsInfo

        if (this.productsInfo) {
          productsInfo = this.productsInfo
        } else {
          let loading = this.$loading()
          productsInfo = await getPlans('')
          loading.close()
        }

        let products = productsInfo.products
        for (let planData of this.plans) {
          let currentPlan = products.find(product => product.user_selected_plan === planData.plan)
          planData.originalPrice = currentPlan.user_original_price.split('/')[0]
          planData.discountPrice = currentPlan.user_your_price.split('/')[0]
        }
      },
      async buyHandle(index) {
        if (this.userInfo) {
          if (!this.productsInfo) {
            let productsInfo = await getPlans(this.userInfo.UID)
            this.$store.commit('set_productsInfo', productsInfo)
          }

          let userSelectedPlan = this.plans[index]
          let orderInfo = this.productsInfo.products.find(item => item.user_selected_plan === userSelectedPlan.plan)
          this.$router.push({ name: 'PricingOrder', params: { orderInfo } })
        } else {
          this.signIn()
        }
      },
      showAnswer(answerIndex) {
        if (this.answerIndex === answerIndex) {
          this.answerIndex = 0
        } else {
          this.answerIndex = answerIndex
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pricingPlan {
    .title {
      font-weight: 900;
      font-size: 32px;
      color: #FFFFFF;
    }
    .page {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .page1 {
      position: relative;
      padding: 75px 0;
      .subtitle {
        margin-top: 20px;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
      }
      .plans {
        margin-top: 100px;
        max-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        .plan {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          padding: 20px 10px;
          width: 264px;
          height: 270px;
          border: 4px solid transparent;
          border-radius: 16px;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, #66C0FF, #6FF8FF);
          box-sizing: border-box;
          .planTag {
            display: flex;
            align-items: center;
            position: absolute;
            top: -17px;
            right: 8px;
            padding: 6px 20px;
            height: 34px;
            border-radius: 34px;
            border: 1px solid #FFFFFF;
            font-family: Roboto;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
            box-sizing: border-box;
          }
          .planName {
            font-family: Roboto;
            font-weight: 700;
            font-size: 32px;
            color: #000000;
            text-align: center;
          }
          .planPrice {
            display: flex;
            align-items: center;
            justify-content: center;
            .planDiscountPrice {
              font-family: Helvetica;
              font-weight: 700;
              font-size: 22px;
              color: #000000;
            }
            .planOriginalPrice {
              margin-left: 6px;
              font-family: Helvetica;
              font-weight: 500;
              font-size: 12px;
              color: #493F3F66;
              text-decoration: line-through;
            }
          }
          .planContent {
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #000000A1;
            text-align: center;
          }
          .buyBtn {
            padding: 0 50px;
            min-width: 180px;
            height: 40px;
            line-height: 40px;
            border-radius: 40px;
            background: #FFE113;
            box-shadow: 0 5px 10px 0 #00000024;
            font-family: Helvetica;
            font-weight: 700;
            font-size: 16px;
            color: #000000;
            overflow: hidden;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.3s;
            flex-shrink: 0;
            &:hover {
              background: #FFCA13;
            }
          }
          &:nth-child(2) {
            margin: 0 24px;
            background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, #FFAF55, #FFE113);
            .planTag {
              background-image: linear-gradient(270deg, #2DA3FF 0%, #1E58FF 100%);
            }
          }
          &:nth-child(3) {
            .planTag {
              background-image: linear-gradient(270deg, #FF9C1E 0%, #FF632D 100%);
            }
          }
        }
      }
      .planExplains {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        max-width: 600px;
        .explainItem {
          display: flex;
          align-items: center;
          opacity: 0.7;
          .explainIcon {
            margin-right: 8px;
            width: 18px;
          }
          .explainText { 
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
          }
        }
      }
    }
    .page2 {
      padding: 50px 0;
      background: #1C22B6;
      .introItems {
        margin-top: 50px;
        width: 100%;
        max-width: 850px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        .introItem {
          padding: 12px;
          width: 40%;
          display: flex;
          align-items: flex-start;
          .introIcon {
            margin-right: 14px;
            width: 30px;
          }
          .introWrap {
            font-family: Roboto;
            color: #FFFFFF;
            .introTitle {
              font-weight: 700;
              font-size: 22px;
              line-height: 30px;
            }
            .introContent {
              margin-top: 6px;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              opacity: 0.8;
            }
          }
        }
      }
    }
    .page3 {
      padding: 45px 0;
      .faqItems {
        margin-top: 45px;
        width: 100%;
        max-width: 800px;
        .faqItem {
          margin-bottom: 20px;
          padding: 0 40px;
          position: relative;
          background: #3A49D8;
          border-radius: 8px;
          font-family: Roboto;
          color: #FFFFFF;
          transition: all 0.3s;
          &.active {
            .arrowIcon {
              transform: rotate(0deg);
            }
            .answer {
              display: block;
            }
          }
          .arrowIcon {
            width: 18px;
            position: absolute;
            top: 16px;
            right: 40px;
            transform: rotate(-90deg);
          }
          .question {
            padding: 15px 0;
            font-weight: 500;
            font-size: 18px;
            cursor: pointer;
          }
          .answer {
            display: none;
            padding: 15px 0;
            border-top: 1px dashed #ffffff6b;
            font-weight: 400;
            font-size: 14px;
            opacity: 0.7;
            white-space: pre-wrap;
            ::v-deep a {
              color: #FFFFFF;
              text-decoration: underline;
            }
          }
        }
      }
      .help {
        margin-top: 90px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        a {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .pricingPlan {
      .mask {
        height: 100%;
      }
      .page {
        padding-left: 5vw;
        padding-right: 5vw;
        box-sizing: border-box;
      }
      .page1 {
        .plans {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          .plan {
            margin-bottom: 20px;
            width: 100% !important;
          }
        }
      }
      .page2 {
        .title {
          text-align: left;
        }
        .introItems {
          .introItem {
            margin-bottom: 20px;
            min-width: unset;
            width: 100%;
            .introIcon {
              display: none;
            }
          }
        }
      }
      .page3 {
        .tryWrap {
          flex-direction: column;
          .tryRightWrap {
            margin-left: 0;
            margin-top: 50px;
          }
        }
      }
    }
  }
</style>