import request from '../utils/request'
import constants from '../utils/constants'
import EncryptApiUtil from '../utils/encryptApiUtil.js'

export async function getPlans(uid) {
  let req = {
    "ep_uid": uid,
    "from": 'web'
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.plansApi,
    method: 'POST',
    data: encryptData.token
  })

  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)
  
  return decryptData.result
}

export async function facebookConvertApi(uid, price) {
  let req = {
    uid,
    action: 'purchase',
    port: 'https://www.fluenday.com/pricing/plan',
    useragent: navigator.userAgent,
    price,
    from: 'web'
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.facebookConvertApi,
    method: 'POST',
    data: encryptData.token
  })

  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  
  return decryptData
}